enum UnknownPageType {
  Category,
  Product,
  Reviews,
  NotFound,
  Redirect,
  LandingPage
}

export default UnknownPageType
