import { Vue, Component, Prop } from 'vue-property-decorator'
import ArrowSvg from '~/assets/svg/link-arrow.svg?inline'
import LinkItem from '~/types/LinkItem'

@Component
export default class LinkSelector extends Vue {
  @Prop({ type: Array, required: true })
  readonly items?: LinkItem[]

  @Prop({ type: String, required: true })
  readonly selected?: string

  isShow: boolean = false

  get selectedText () {
    return this.items?.find(e => e.code === this.selected)?.title
  }

  get list () {
    return this.items?.filter(e => e.code !== this.selected)
  }

  showList () {
    if (this.isShow) {
      return (
        <div class="link-selector__list">
          {this.list?.map(e => (
            <nuxt-link {...{
              class: 'link-selector__item',
              props: {
                to: e.location
              },
              nativeOn: {
                click: () => {
                  this.close()
                }
              }
            }}>{e.title}</nuxt-link>
          ))}
        </div>
      )
    }
  }

  close () {
    this.isShow = false
  }

  toggle () {
    this.isShow = !this.isShow
  }

  render () {
    return (
      <div {...{
        class: 'link-selector',
        directives: [{
          name: 'VueClickOutside',
          value: this.close
        }]
      }}>
        <div {...{
          class: 'link-selector__selected',
          on: {
            click: this.toggle
          }
        }}>
          <div class="link-selector__selected-text">
            {this.selectedText}
          </div>
          <div class="link-selector__selected-arrow">
            <ArrowSvg />
          </div>
        </div>
        {this.showList()}
      </div>
    )
  }
}
