import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import ILandingPage, { ILandingPageOptions } from '~/types/api/LandingPage'
import ITagsCloudElement from '~/types/api/TagsCloudElement'
import config from '~/utils/config'
import s from '~/utils/s'
import * as count from '~/utils/config/productCount'
import Product from '~/types/api/Product'

type Dictionary<T> = { [key: string]: T }

@Module({
  name: 'landingPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _tagsCloud: ITagsCloudElement[] = []
  _landingPage: ILandingPage = {
      id: '',
      title: '',
      categoryId: '',
      isActive: false,
      isDisplay: false,
      products: [],
      count: 0,
      HTMLContent: '',
      textContent: ''
    }

  _page: number = 0
  _limit: number = 12
  _isLoadMore: boolean = false

  @Mutation
  setTagsCloud (tagsCloud: ITagsCloudElement[]) {
    this._tagsCloud = tagsCloud
  }

  @Mutation
  setLandingPage (landingPage: ILandingPage) {
    this._landingPage = landingPage
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._landingPage.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  setLandingPageItems (items: Product[]) {
    this._landingPage.products = items
  }

  @Mutation
  setLandingPageCount (count: number) {
    this._landingPage.count = count
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }



  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Action({ rawError: config.rawError })
  async loadTagsCloud(categoryId: string) {
    const regionId = s(this.store).regions.regionId ?? 0
    const sectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.getTagsCloud(categoryId, regionId, sectionId)
    if (result.code === 200) {
      this.setTagsCloud(result.data ?? [])
    }
  }

  @Action({ rawError: config.rawError })
  async loadLandingPage (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }
    const landingPageUrl = route.path.split('?')[0].split('/').filter(e => e !== '').reverse()[0]
    const regionId = s(this.store).regions.regionId ?? 0
    const sectionId = s(this.store).regions.sectionId
    const options: ILandingPageOptions = {
      landingPageUrl,
      regionId,
      sectionId
    }
    await s(this.store).landingPageFilter.fillQueryFilter(route)
    await s(this.store).landingPageFilter.fillSelectedFilter()
    const query = route.query
    const limit = count.checkValue(query.count)
    this.setLimit(limit)
    const page = await this.getPageNumber(query)
    const offset = page * limit
    options.limit = limit
    options.offset = offset
    options.filter = s(this.store).landingPageFilter.queryFilter
    const result = await this.store.$api.getLandingPage({ ...options, ...query })
    if (result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setLandingPageItems([...this._landingPage?.products!, ...result.data.products!])
        this.setLandingPageCount(result.data.count)
        setTimeout(() => window.scroll({
          top: offsetScroll,
          left: 0
        }), 0)
        this.disableLoadMore()
      } else {
        this.setLandingPage(result.data)
      }
      this.setPage(page)
    }
  }

  @Action({ rawError: config.rawError })
  async loadLandingPageFilters (options: ILandingPageOptions) {
    const result = await this.store.$api.getLandingPageFilters(options)
    if (result.data) {
      s(this.store).landingPageFilter.setList(result.data)
    }
  }

  @Action({ rawError: config.rawError })
  getPageNumber (query: Dictionary<string | (string | null)[]>) {
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      let page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
      return page
    }
    return 0
  }

  get tagsCloud (): ITagsCloudElement[] {
    return this._tagsCloud
  }

  get landingPage (): ILandingPage {
    return this._landingPage
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get pageCount (): number {
    return Math.ceil(this.landingPage.count / this.limit)
  }

  get showInfo (): boolean {
    return this.landingPage.HTMLContent !== '' || this.landingPage.textContent !== ''
  }
}
