import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import DataForResult from '../types/DataForResult'
import ITechRehab, { ICodesForHeader } from '../types/api/TechRehab'
import Result from '~/types/api/Result'
import SectionHelper from '~/utils/sectionHelper'
import * as sort from '~/utils/config/productSort'
import * as count from '~/utils/config/productCount'
import ListResponse from '~/types/api/ListResponse'
import Product from '~/types/api/Product'
import config from '~/utils/config'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'
import { SfCatalogCategory } from '~/types/SfCatalogCategory'
import Img from '~/types/api/Img'

type Dictionary<T> = { [key: string]: T }

interface LoadCategoryOptions {
  route: Route,
  sectionId: string
}

interface Category {
  name: string;
  id: string;
  items: Product[];
  count: number;
  img?: Img;
  description?: string;
  HTMLDescription?: string;
  RegionHTML?: string;
  RegionDescription?: string;
  RegionImg?: Img;
  seo?: {
    seoUrl: string;
  },
  alternativeDisplay: boolean,
  isPreset: boolean
}

@Module({
  name: 'categoryPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _category: Category = {
    name: '',
    id: '0',
    items: [],
    count: 0,
    alternativeDisplay: false,
    isPreset: false
  }

  _dataResult: DataForResult = {
    total: 0,
    totalSum: 0,
    discount: 0,
    totalDiscountPrice: 0,
    productIds: []
  }

  _viewedProducts: ListResponse<Product> = {
    count: 0,
    items: []
  }

  _codesForHeader: ICodesForHeader[] = []
  _limit: number = 15
  _page: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false
  _pathSections: SfSection[] = []
  _countItemsSection: number = 0

  @Mutation
  setCategory (category: Category) {
    this._category = category
  }

  @Mutation
  setResultData (data: DataForResult) {
    this._dataResult = data
  }

  @Mutation
  setCategoryItems (items: Category['items']) {
    this._category.items = items
  }

  @Mutation
  setCategoryCount (count: Category['count']) {
    this._category.count = count
  }

  @Mutation
  setViewedProducts (viewedProducts: ListResponse<Product>) {
    this._viewedProducts = viewedProducts
  }

  @Mutation
  setPathSections (pathSections: SfSection[]) {
    this._pathSections = pathSections
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._category.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  setIsPreset (bool :boolean) {
    this._category.isPreset = bool
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Mutation
  setCountItemsSection (value: number) {
    this._countItemsSection = value
  }

  @Mutation
  setCodesForHeader (value: ICodesForHeader[]) {
    this._codesForHeader = value
  }

  @Action({ rawError: config.rawError })
  clearIsPreset () {
    this.setIsPreset(false)
  }

  @Action({ rawError: config.rawError })
  setDefaultCategory () {
    this.setCategory({
      name: '',
      id: '0',
      items: [],
      count: 0,
      alternativeDisplay: false,
      isPreset: false
    })
  }

  @Action({ rawError: config.rawError })
  async loadCategory (options: LoadCategoryOptions) {
    this.setCategory({
      name: '',
      id: '0',
      items: [],
      count: 0,
      alternativeDisplay: false,
      isPreset: false
    })
    const targetSection = s(this.store).general.sections.find((section: SfSection) => section.id === options.sectionId)
    if (targetSection) {
      const helper = new SectionHelper(s(this.store).general.sections, this.store)
      this.setPathSections(helper.getSectionsPath(targetSection.id))
      this.setCategory({
        ...this.category,
        id: targetSection.id
      })
      this.disableLoader()
      await this.loadProducts(options.route)
      this.loadViewedProducts()
      s(this.store).seoTextBlock.loadSeoText('catalog')
    }
  }

  @Action({ rawError: config.rawError })
  async loadViewedProducts () {
    const result = await this.store.$api.getProductViewed({
      limit: 8,
      offset: 0,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId
    })

    if (result.code === 200 && result.data) {
      this.setViewedProducts(result.data)
    } else {
      this.setViewedProducts({
        count: 0,
        items: []
      })
    }
  }

  @Action({ rawError: config.rawError })
  async loadCategoryFilters ({ categoryId, limit }: { categoryId: string, limit: number }) {
    // s(this.store).categoryFilter.setList([])
    s(this.store).categoryFilter.setLoading(true)
    const result = await this.store.$api.fetchCategoryFilters({
      id: categoryId,
      regionId: s(this.store).regions.regionId,
      sectionId: s(this.store).regions.sectionId
    })
    if (result.code === 200 && result.data) {
      s(this.store).categoryFilter.setList(result.data.filters)
      const filters = (await s(this.store).categoryPage.getNameCertificate(s(this.store).categoryFilter.list.filter(item => item.code === 'code')[0].values!))!
      this.setCodesForHeader(filters)
    } else {
      s(this.store).categoryFilter.setList([])
    }
    s(this.store).categoryFilter.setLoading(false)
  }

  @Action({ rawError: config.rawError })
  async getCountItemsSection (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    if (this.category && s(this.store).regions.section) {
      // если переход из другой страницы, непоказывает лоадер
      // if (this.isLoaded) {
      s(this.store).general.enableLoader()
      // }
      s(this.store).categoryFilter.fillQueryFilter(route)
      const query = route.query

      const limit = count.checkValue(query.count)

      const sortType = sort.checkValue(query.sort)
      const page = await this.getPageNumber(query)

      const offset = page * limit

      const result = await this.store.$api.getCountItemsCatalogSection({
        id: this.category.id,
        limit,
        offset,
        regionId: s(this.store).regions.regionId ?? 0,
        sortType,
        filter: s(this.store).categoryFilter.queryFilter,
        sectionId: s(this.store).regions.sectionId
      })
      if (result.code === 200 && result.data) {
        this.setCountItemsSection(result.data)
      }
      s(this.store).general.disableLoader()
    }
  }

  @Action({ rawError: config.rawError })
  isThereDpdAdsToRender () {
    return s(this.store).advertising.isActive
  }

  @Action({ rawError: config.rawError })
  isThereCatalogAdsToRender () {
    return s(this.store).productCardsCommercial.productCardsCommercial.length > 0
  }

  @Action({ rawError: config.rawError })
  async getDpdStatus () {
    await s(this.store).advertising.checkAdvStatus()
  }

  @Action({ rawError: config.rawError })
  async calculateAvailableProductSlots (productsPerPageLimit: number) {
    let availableProductSlots = productsPerPageLimit
    await this.getDpdStatus()

    if (await this.isThereDpdAdsToRender()) {
      availableProductSlots -= 1
    }
    if (await this.isThereCatalogAdsToRender()) {
      availableProductSlots -= 1
    }

    return availableProductSlots
  }

  @Action({ rawError: config.rawError })
  async loadProducts (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }
    s(this.store).seo.setCanonical(route.path)

    if (this.category && s(this.store).regions.section) {
      // если переход из другой страницы, непоказывает лоадер

      s(this.store).general.enableLoader()

      s(this.store).categoryFilter.fillQueryFilter(route)
      const query = route.query

      await s(this.store).productCardsCommercial.loadProductCardsCommercial(s(this.store).categoryPage.category.id)

      const limit = await this.calculateAvailableProductSlots(count.checkValue(query.count))

      const sortType = sort.checkValue(query.sort)
      let page = await this.getPageNumber(query)
      if (this.countItemsSection && page + 1 > Math.ceil(this.countItemsSection / limit)) {
        page = 0
      }

      const offset = page * limit

      const result = await this.store.$api.getCatalogSection({
        id: this.category.seo?.seoUrl ?? this.category.id,
        limit,
        offset,
        regionId: s(this.store).regions.regionId,
        sortType,
        filter: s(this.store).categoryFilter.queryFilter,
        sectionId: s(this.store).regions.sectionId
      })

      if (result.data?.category.isPreset) {
        const dataForResult = await this.store.$api.getCatalogResult({
          categoryId: this.category.seo?.seoUrl ?? this.category.id,
          regionId: s(this.store).regions.regionId,
          sectionId: s(this.store).regions.sectionId
        })
        this.setResultData(dataForResult.data!)
      }
      await this.resultParse(result)
      this.setLimit(limit)
      this.setPage(page)
      s(this.store).general.disableLoader()
    }
  }

  @Action({ rawError: config.rawError })
  getPageNumber (query: Dictionary<string | (string | null)[]>) {
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      let page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
      return page
    }
    return 0
  }

  @Action({ rawError: config.rawError })
  async resultParse (result: Result<SfCatalogCategory>) {
    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setCategoryItems([...this._category.items, ...result.data.category.products])
        this.setCategoryCount(result.data.category.count)
        setTimeout(() => window.scroll({
          top: offsetScroll,
          left: 0
        }), 0)
        this.disableLoadMore()
      } else {
        const { id, name, products } = result.data.category
        this.setCategory({
          id,
          name,
          items: products,
          count: result.data.category.count,
          img: result.data.category.img,
          description: result.data.category.description,
          HTMLDescription: result.data.category.HTMLDescription,
          RegionDescription: result.data.category.RegionDescription,
          RegionHTML: result.data.category.RegionHTML,
          RegionImg: result.data.category.RegionImg,
          alternativeDisplay: result.data.category.alternativeDisplay,
          isPreset: result.data.category.isPreset
        })
      }
      this.setCountItemsSection(result.data.category.count)
      await s(this.store).categoryFilter.fillSelectedFilter()
    } else {
      this.setCategory({
        name: 'Not Found',
        id: '0',
        items: [],
        count: 0,
        alternativeDisplay: false,
        isPreset: false
      })
    }
  }

  @Action({ rawError: config.rawError })
  async getNameCertificate (codes: string[]) {
    const regionId = s(this.store).regions.regionId ?? 0
    const result = await this.store.$api.getNamesForCertificate(codes, regionId, s(this.store).regions.sectionId)
    if (result.code === 200) {
      const data = result.data as ITechRehab[]
      const certificateData: ICodesForHeader[] = []
      data.map((item) => {
        certificateData.push({ code: item.code, name: item.name })
      })
      return certificateData
    }
  }

  get category (): Category {
    return this._category
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pathSections (): SfSection[] {
    return this._pathSections
  }

  get pageCount (): number {
    return Math.ceil(this.category.count / this.limit)
  }

  get viewedProducts (): ListResponse<Product> {
    return this._viewedProducts
  }

  get countItemsSection (): number {
    return this._countItemsSection
  }

  get dataResult (): DataForResult {
    return this._dataResult
  }

  get codesForHeader (): ICodesForHeader[] {
    return this._codesForHeader
  }
}
