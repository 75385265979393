import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosRequestConfig, CancelTokenSource } from 'axios'
import qs from 'qs'
import { Store } from 'vuex'
import DataForResult from '../../types/DataForResult'
import Api, { ChangePhoneNumber } from './Api'
import Result from '~/types/api/Result'
import ListOptions from '~/types/api/options/ListOptions'
import ListResponse, { ListResponseUpl, ListResponseWithIds } from '~/types/api/ListResponse'
import Product from '~/types/api/Product'
import { Region } from '~/types/api/Region'
import { MainSliderItem } from '~/types/api/MainSliderItem'
import { MainSliderAdvertisementItem } from '~/types/api/MainSliderAdvertisementItem'
import Token from '~/types/api/Token'
import CatalogOptions from '~/types/api/options/CatalogOptions'
import CartOptions from '~/types/api/options/CartOptions'
import Cart from '~/types/api/Cart'
import ErrorResponse from '~/types/api/ErrorResponse'
import User from '~/types/api/User'
import AuthorizationOptions from '~/types/api/options/AuthorizationOptions'
import ManagerErrorHandler from '~/utils/api/errorHandler/ManagerErrorHandler'
import BooleanStatus from '~/types/api/BooleanStatus'
import Email from '~/types/api/Email'
import Phone from '~/types/api/Phone'
import VerificationCode from '~/types/api/VerificationCode'
import RegistrationOptions from '~/types/api/options/RegistrationOptions'
import ProductDetailOptions from '~/types/api/options/ProductDetailOptions'
import ProductDetail from '~/types/api/ProductDetail'
import Review from '~/types/api/Review'
import Order from '~/types/api/order/Order'
import FavoritesOptions from '~/types/api/options/FavoritesOptions'
import SearchBarOptions from '~/types/api/options/SearchBarOptions'
import SearchBar from '~/types/api/search/SearchBar'
import SearchOptions from '~/types/api/options/SearchOptions'
import SearchResult from '~/types/api/search/SearchResult'
import ArticleListOptions from '~/types/api/options/ArticleListOptions'
import ArticleList from '~/types/api/news/ArticleList'
import ArticleDetailOptions from '~/types/api/options/ArticleDetailOptions'
import ArticleDetail from '~/types/api/news/ArticleDetail'
import Stock from '~/types/api/stock/Stock'
import StockDetailOptions from '~/types/api/options/StockDetailOptions'
import StockProductOptions from '~/types/api/options/StockProductOptions'
import StockDetail from '~/types/api/stock/StockDetail'
import UpdateProfile from '~/types/api/options/UpdateProfile'
import ChangePasswordOptions from '~/types/api/options/ChangePasswordOptions'
import OrderHistory from '~/types/api/orderHistory/OrderHistory'
import DiseaseSection from '~/types/api/disease/DiseaseSection'
import DiseaseListOptions from '~/types/api/options/DiseaseListOptions'
import DiseaseList from '~/types/api/disease/DiseaseList'
import DiseaseDetail from '~/types/api/disease/DiseaseDetail'
import OrderOptions from '~/types/api/order/OrderOptions'
import OrderSaveResult from '~/types/api/order/OrderSaveResult'
import StreetDadata from '~/types/api/order/StreetDadata'
import StreetDadataOptions from '~/types/api/order/StreetDadataOptions'
import PhoneResetPassword from '~/types/api/PhoneResetPassword'
import ResponsePhoneResetPassword from '~/types/api/ResponsePhoneResetPassword'
import ChangePasswordCodeOptions from '~/types/api/options/ChangePasswordCodeOptions'
import ProductViewedOptions from '~/types/api/options/ProductViewedOptions'
import BasketRegionCount from '~/types/api/BasketRegionCount'
import Theme from '~/types/api/Theme'
import FeedbackForm from '~/types/api/options/FeedbackForm'
import OrderRatingOptions from '~/types/api/options/OrderRatingOptions'
import HouseDadata from '~/types/api/order/HouseDadata'
import HouseDadataOptions from '~/types/api/order/HouseDadataOptions'
import PharmacyResponse from '~/types/api/pharmacy/PharmacyResponse'
import Pharmacy from '~/types/api/pharmacy/Pharmacy'
import OrderHistoryDetailOptions from '~/types/api/options/OrderHistoryDetailOptions'
import OrderHistoryDetail from '~/types/api/orderHistory/OrderHistoryDetail'
import SeoDynamicOptions from '~/types/api/seo/SeoDynamicOptions'
import SeoData from '~/types/seo/SeoData'
import SeoStaticOptions from '~/types/api/seo/SeoStaticOptions'
import OrderPaymentResultOptions from '~/types/api/options/OrderPaymentResultOptions'
import OrderPaymentResult from '~/types/api/OrderPaymentResult'
import AddressDadataOptions from '~/types/api/order/AddressDadataOptions'
import AddressDadata from '~/types/api/order/AddressDadata'
import UserOrdersOptions from '~/types/api/options/UserOrdersOptions'
import PartnerCardBlockOptions from '~/types/api/options/PartnerCardBlockOptions'
import ProductCardCommercialOptions from '~/types/api/options/ProductCardCommercialOptions'
import ReviewOptions from '~/types/api/options/ReviewOptions'
import ReviewByUserOptions from '~/types/api/options/ReviewByUserOptions'
import ReviewListOptions from '~/types/api/options/ReviewListOptions'
import SeoTextBlock from '~/types/api/SeoTextBlock'
import SeoTextBlockOptions from '~/types/api/options/SeoTextBlockOptions'
import HelpPageText from '~/types/api/HelpPageText'
import HelpPageTextOptions from '~/types/api/options/HelpPageTextOptions'
import TypeDevice from '~/types/enum/TypeDevice'
import IdProductWaitingList from '~/types/api/IdProductWaitingList'
import Topic from '~/types/api/Topic'
import Preset from '~/types/api/Preset'
import PresetOptions from '~/types/api/options/PresetOptions'
import PresetCategory from '~/types/api/PresetCategory'
import SmsAuthOptions from '~/types/api/options/SmsAuthOptions'
import { SfResult } from '~/types/api/SfResult'
import { VerificationPhoneParams } from '~/types/api/VerificationPhoneParams'
import SfCart, { CartItem, WrapperSfCart } from '~/types/api/SfCart'
import { SfSection } from '~/types/api/SfSection'
import { SfCatalogCategory } from '~/types/SfCatalogCategory'
import { BANNER_GROUP_CODE } from '~/types/enum/BannerGroups'
import FilterItem from '~/types/api/filter/FilterItem'
import { RecipeResponse } from '~/types/api/recipe/RecipeResponse'
import { EsiaLinkResponse } from '~/types/api/recipe/EsiaLinkResponse'
import { RecipeStatus } from '~/types/api/recipe/RecipeStatus'
import { RecipeListOptions } from '~/types/api/recipe/RecipeListOptions'
import { EsiaLogoutResponse } from '~/types/api/recipe/EsiaLogoutResponse'
import StaticPage from '~/types/api/StaticPage'
import OrderCopyOptions from '~/types/api/options/OrderCopyOptions'
import OrderPickpoint from '~/types/api/order/OrderPickpoint'
import { MetrikaCode } from '~/types/api/MetrikaCode'
import License from '~/types/api/License'
import { UserDefaultAddress } from '~/types/api/UserDefaultAddress'
import { SiteSetting } from '~/types/api/SiteSetting'
import { userProductListData, userProductListDataAdd, userProductListDataAll, getUserListOptions, IUserProductListLightweight } from '~/types/api/AidKit'
import CollectionProductListResponse from '~/types/api/CollectionProductListResponse'
import CollectionProductListOptions from '~/types/api/options/CollectionProductListOptions'
import BannerOptions from '~/types/api/options/BannerOptions'
import CategoriesResponse from '~/types/api/categories/CategoriesResponse'
import { CategoriesOptions } from '~/types/api/categories/CategoriesOptions'
import { MarkingAdInfo } from '~/types/api/MarkingAdTypes'
import ITechRehab from '~/types/api/TechRehab'
import SliderProducts from '~/types/api/sliderProducts'
import ITagsCloudElement from '~/types/api/TagsCloudElement'
import ILandingPage, { ILandingPageOptions } from '~/types/api/LandingPage'

export interface SmsAuthResponse {
  tokens: Tokens
  user: User
}

export interface Tokens {
  jwt: string,
  tokenBitrix?: string
  rt: string
}

export interface SfAuthConfig {
  baseURL: string;
  headers: {
    Authorization: string;
    'x-refresh-token': string;
  }
}

export default class implements Api {
  protected axios: NuxtAxiosInstance;
  protected errorManager: ManagerErrorHandler

  protected sfAuthConfig: SfAuthConfig = {
    baseURL: process.server ? (process.env.NUXT_ENV_API_URL_SF || 'http://api:9997/api') : (process.env.NUXT_ENV_API_BROWSER_URL_SF || 'http://localhost:9997/api'),
    headers: {
      Authorization: '',
      'x-refresh-token': ''
    }
  };

  constructor (axios: NuxtAxiosInstance, store: Store<any>) {
    this.axios = axios
    this.errorManager = new ManagerErrorHandler(store, axios)
  }

  setTypeDevice (type: TypeDevice): void {
    this.axios.setHeader('Type-Device', type)
  }

  setConstructionHeader (value: string) {
    this.axios.setHeader('Test-Api', value)
  }

  setToken (token: string): void {
    this.axios.setToken(token, 'Bearer')
  }

  setSfToken (token: string): void {
    this.sfAuthConfig.headers.Authorization = `Bearer ${token}`
  }

  setRefreshToken (token: string) {
    this.sfAuthConfig.headers['x-refresh-token'] = token
  }

  async refreshToken (): Promise<Result<Tokens>> {
    try {
      const data = await this.axios.$post<SfResult<Tokens>>('/refresh-token', {}, this.sfAuthConfig)
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.refreshToken.bind(this))
    }
  }

  async getCatalogSections (regionsId: string, sectionId: string, isSlider = false): Promise<Result<SfSection[]>> {
    try {
      const data = await this.axios.$get<SfResult<{ categories: SfSection[] }>>('catalog/sections', {
        params: {
          regionId: regionsId,
          sectionId,
          isSlider
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result.categories
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getCatalogSections.bind(this), regionsId)
    }
  }

  async getNamesForCertificate (codes: string[], regionsId: string, sectionId: string): Promise<Result<ITechRehab[]>> {
    try {
      const data = await this.axios.$get<SfResult<{ techRehab: ITechRehab[] }>>('catalog/certificate/', {
        params: {
          regionId: regionsId,
          sectionId,
          codes
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result.techRehab
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getNamesForCertificate.bind(this), codes)
    }
  }

  async getCatalogSection (options: CatalogOptions): Promise<Result<SfCatalogCategory>> {
    try {
      const data = await this.axios.$get<SfResult<SfCatalogCategory>>(`catalog/sections/${options.id}`, {
        params: { ...options },
        paramsSerializer: params => qs.stringify(params),
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([480], error, this.getCatalogSection.bind(this), options)
    }
  }

  async getCatalogResult (options: {categoryId: string, regionId: string, sectionId: string}): Promise<Result<DataForResult>> {
    try {
      const data = await this.axios.$get<SfResult<DataForResult>>('catalog/result/category', {
        params: { ...options },
        paramsSerializer: params => qs.stringify(params),
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([480], error, this.getCatalogResult.bind(this), options)
    }
  }

  async catalogResultAddToCart (productIds: string[], regionId: string, sectionId: string): Promise<Result> {
    try {
      const data = await this.axios.$post<SfResult<DataForResult>>('catalog/result', { productIds }, {
        params: {
          regionId,
          sectionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([480], error, this.catalogResultAddToCart.bind(this), productIds)
    }
  }

  async getCountItemsCatalogSection (options: CatalogOptions): Promise<Result<number>> {
    try {
      const data = await this.axios.$get<SfResult<{ count: number }>>(`catalog/sections/${options.id}/count`, {
        ...this.sfAuthConfig,
        params: options,
        paramsSerializer: params => qs.stringify(params)
      })
      return {
        code: 200,
        data: data.result.count
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getCountItemsCatalogSection.bind(this), options)
    }
  }

  async getProducts (regionId: string): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<{ products: Product[], count: number }>>('/products', {
        ...this.sfAuthConfig,
        params: {
          regionId
        }
      })
      return {
        code: 200,
        data: {
          items: data.result.products,
          count: data.result.count
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getProducts.bind(this))
    }
  }

  async getProductPromotions (options: ListOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<{ products: Product[], count: number, filters: FilterItem[]}>>('catalog/stocks', {
        ...this.sfAuthConfig,
        params: options,
        paramsSerializer: params => qs.stringify(params)
      })
      return {
        code: 200,
        data: {
          items: data.result.products,
          count: data.result.count,
          filters: data.result.filters
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getProductPromotions.bind(this), options)
    }
  }

  async getSliderProducts (sliderType: string, options: ListOptions): Promise<Result<SliderProducts<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<{ products: Product[] }>>(`catalog/slider/${sliderType}`, {
        ...this.sfAuthConfig,
        params: options,
        paramsSerializer: params => qs.stringify(params)
      })
      return {
        code: 200,
        data: {
          items: data.result.products
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getSliderProducts.bind(this), options)
    }
  }

  async getProductPopular (options: ListOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<{ products: Product[], count: number, filters: FilterItem[]}>>('products/popular', {
        params: options,
        paramsSerializer: params => qs.stringify(params),
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: {
          items: data.result.products,
          count: data.result.count,
          filters: data.result.filters
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getProductPopular.bind(this), options)
    }
  }

  async getProductNovelties (options: ListOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<{ products: Product[], count: number, filters: FilterItem[]}>>('catalog/new', {
        ...this.sfAuthConfig,
        params: options,
        paramsSerializer: params => qs.stringify(params)
      })
      return {
        code: 200,
        data: {
          items: data.result.products,
          count: data.result.count,
          filters: data.result.filters
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getProductNovelties.bind(this), options)
    }
  }

  async getPresetList (regionId: string): Promise<Result<Preset[]>> {
    try {
      const data = await this.axios.$get<Preset[]>('presets', {
        params: {
          regionId
        }
      })

      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getPresetList.bind(this), regionId)
    }
  }

  async getPresetProducts (options: PresetOptions): Promise<Result<PresetCategory>> {
    try {
      const data = await this.axios.$get<PresetCategory>(`presets/${options.code}`, {
        params: options
      })

      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getPresetProducts.bind(this), options)
    }
  }

  async getProductViewed (options: ListOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<Product>>>('products/viewed', {
        ...this.sfAuthConfig,
        params: options
      })

      return {
        code: 200,
        data: {
          items: data.result.items,
          count: data.result.count
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getProductViewed.bind(this), options)
    }
  }

  async addProductViewed (options: ProductViewedOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>(`product/viewed/${options.productId}`, options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addProductViewed.bind(this), options)
    }
  }

  async addReview (options: ReviewOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<SfResult<BooleanStatus>>(`/products/${options.review.productId}/reviews`, {
        ...options.review,
        regionId: options.regionId
      }, this.sfAuthConfig)

      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addReview.bind(this), options)
    }
  }

  async updateReview (options: ReviewOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$put<SfResult<BooleanStatus>>(`products/reviews/${options.review.id}`, {
        ...options.review,
        regionId: options.regionId
      }, this.sfAuthConfig)

      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addReview.bind(this), options)
    }
  }

  async getReviews (options: ReviewListOptions): Promise<Result<ListResponse<Review>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<Review>>>(`/products/${options.productId}/reviews`, {
        params: options,
        ...this.sfAuthConfig
      })

      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getReviews.bind(this), options)
    }
  }

  async getReviewByUser (options: ReviewByUserOptions): Promise<Result<Review>> {
    try {
      const data = await this.axios.$get<SfResult<{ review: Review }>>(`products/${options.productId}/reviews/by-user`, {
        params: options,
        ...this.sfAuthConfig
      })

      return {
        code: 200,
        data: data.result.review
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getReviewByUser.bind(this), options)
    }
  }

  async getPartnerCardBlock (options: PartnerCardBlockOptions): Promise<Result<MainSliderItem[]>> {
    try {
      // const data = await this.axios.$get<PartnerCard[]>('content/partner-card-advertisement', {
      //   params: options
      // })
      const data = await this.axios.$get<SfResult<{ items: MainSliderItem[] }>>('banners', {
        ...this.sfAuthConfig,
        params: {
          regionId: options.regionId,
          sectionId: options.sectionId,
          group: options.bannerGroup
        }
      })
      return {
        code: 200,
        data: data.result.items
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getPartnerCardBlock.bind(this), options)
    }
  }

  async getProductCardCommercial (options: ProductCardCommercialOptions): Promise<Result<MainSliderItem[]>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<MainSliderItem>>>('/banners', {
        ...this.sfAuthConfig,
        params: {
          ...options,
          group: BANNER_GROUP_CODE.catalogBanner
        }
      })
      return {
        code: 200,
        data: data.result.items
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getProductCardCommercial.bind(this), options)
    }
  }

  async getSeoTextBlock (options: SeoTextBlockOptions): Promise<Result<SeoTextBlock>> {
    try {
      const data = await this.axios.$get<SeoTextBlock>('seo/seo-text-block', {
        params: options
      })
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getSeoTextBlock.bind(this), options)
    }
  }

  async getHelpPageText (options: HelpPageTextOptions): Promise<Result<HelpPageText>> {
    try {
      const data = await this.axios.$get<HelpPageText>('content/text-page', {
        params: options
      })
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getHelpPageText.bind(this), options)
    }
  }

  async getRegions (): Promise<Result<Region[]>> {
    try {
      const data = await this.axios.$get<SfResult<{ regions: Region[] }>>('regions', { ...this.sfAuthConfig })
      return {
        code: 200,
        data: data.result.regions
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getRegions.bind(this))
    }
  }

  async getMainSliderItems (regionId: string, sectionId: string): Promise<Result<MainSliderItem[]>> {
    try {
      const data = await this.axios.$get<SfResult<{ items: MainSliderItem[] }>>('banners', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId,
          group: BANNER_GROUP_CODE.mainPageSlider
        }
      })
      return {
        code: 200,
        data: data.result.items
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getMainSliderItems.bind(this), regionId, sectionId)
    }
  }

  async getMainSliderAdvertisementItems (regionId: string, sectionId: string): Promise<Result<MainSliderAdvertisementItem[]>> {
    try {
      const data = await this.axios.$get<SfResult<{ items: MainSliderAdvertisementItem[]}>>('banners', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId,
          group: BANNER_GROUP_CODE.mainPageSliderAlt
        }
      })
      return {
        code: 200,
        data: data.result.items
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getMainSliderAdvertisementItems.bind(this), regionId)
    }
  }

  async getToken (): Promise<Result<Token>> {
    try {
      const data = await this.axios.$get<Token>('token')
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return {
        code: error.response.status,
        error: {
          description: error.response.statusText
        }
      }
    }
  }

  async getAuthorizeToken (): Promise<Result<Token>> {
    try {
      const data = await this.axios.$get<SfResult<Token>>('/auth/token', this.sfAuthConfig)
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getAuthorizeToken.bind(this))
    }
  }

  async getCart (regionId: string, sectionId: string): Promise<Result<Cart>> {
    try {
      const { result: { cart, totalSum, count, totalDiscountPrice, discount } } = await this.axios.$get<SfResult<SfCart>>('/cart', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: {
          items: cart.items ?? [],
          price: totalDiscountPrice,
          basePrice: totalSum,
          discountPrice: discount,
          message: '',
          count: count ?? 0
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getCart.bind(this), regionId)
    }
  }

  async addCart (options: CartOptions): Promise<Result<Cart>> {
    try {
      const { result: { cartData: { cart, totalSum, count, totalDiscountPrice, discount } } } = await
      this.axios.$post<SfResult<WrapperSfCart>>(`/products/${options.productId}/cart`, options, {
        ...this.sfAuthConfig,
        params: {
          regionId: options.regionId,
          sectionId: options.sectionId
        }
      })
      return {
        code: 200,
        data: {
          items: cart.items ?? [],
          price: totalDiscountPrice,
          basePrice: totalSum,
          discountPrice: discount,
          message: '',
          count: count ?? 0
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addCart.bind(this), options)
    }
  }

  async addCartPreset (options: PresetOptions): Promise<Result<Cart>> {
    try {
      const data = await this.axios.$post<Cart>(`cart/add-preset/${options.code}`, options)

      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addCartPreset.bind(this), options)
    }
  }

  async copyOrder (options: OrderCopyOptions): Promise<Result<Cart>> {
    try {
      const { result: { cartData: { cart, totalSum, count, totalDiscountPrice, discount }, itemAmount } } = await this.axios.$post<SfResult<WrapperSfCart>>(`orders/repeat/${options.id}`, options, {
        ...this.sfAuthConfig,
        params: {
          regionId: options.regionId,
          sectionId: options.sectionId
        }
      })
      return {
        code: 200,
        data: {
          items: cart.items ?? [],
          price: totalDiscountPrice,
          basePrice: totalSum,
          discountPrice: discount,
          message: '',
          count: count ?? 0,
          orderItems: itemAmount
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.copyOrder.bind(this), options)
    }
  }

  async changeCart (options: CartOptions): Promise<Result<Cart>> {
    try {
      const { result: { cartData: { cart, totalSum, count, totalDiscountPrice, discount } } } = await this.axios.$put<SfResult<WrapperSfCart>>(`/products/${options.productId}/cart`, options, {
        ...this.sfAuthConfig,
        params: {
          regionId: options.regionId,
          sectionId: options.sectionId
        }
      })
      return {
        code: 200,
        data: {
          items: cart.items ?? [],
          price: totalDiscountPrice,
          basePrice: totalSum,
          discountPrice: discount,
          message: '',
          count: count ?? 0
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.changeCart.bind(this), options)
    }
  }

  async removeCart (options: CartOptions): Promise<Result<Cart>> {
    try {
      // на беке не lumen не хочет тело запроса обрабатывать, поэтому передаю и туда и в query
      const { result: { cartData: { cart, totalSum, count, totalDiscountPrice, discount } } } = await
      this.axios.$delete<SfResult<WrapperSfCart>>(`/products/${options.productId}/cart`, {
        ...this.sfAuthConfig,
        params: {
          regionId: options.regionId,
          sectionId: options.sectionId
        }
      })
      return {
        code: 200,
        data: {
          items: cart.items ?? [],
          price: totalDiscountPrice,
          basePrice: totalSum,
          discountPrice: discount,
          message: '',
          count: count ?? 0
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.removeCart.bind(this), options)
    }
  }

  async removeCartRows (rowIds: string[], regionId: string, sectionId: string): Promise<Result<Cart>> {
    try {
      const { result: { cartData: { cart, totalSum, count, totalDiscountPrice, discount } } } = await
      this.axios.$put<SfResult<WrapperSfCart>>('/cart', {
        ids: rowIds
      }, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: {
          items: cart.items ?? [],
          price: totalDiscountPrice,
          basePrice: totalSum,
          discountPrice: discount,
          message: '',
          count: count ?? 0
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.removeCart.bind(this))
    }
  }

  async login (options: AuthorizationOptions): Promise<Result<User>> {
    try {
      const data = await this.axios.$post<User>('login/v2', options)
      const fcmToken = localStorage.getItem('fcmToken')

      if (fcmToken) {
        this.addFirebaseToken(fcmToken)
      }

      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.login.bind(this), options)
    }
  }

  /* async smsAuth (options: SmsAuthOptions): Promise<Result<User>> {
    try {
      const data = await this.axios.$post<User>('sms-auth', options)
      const fcmToken = getCookieClient('fcmToken')

      if (fcmToken) {
        this.addFirebaseToken(fcmToken)
      }

      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.login.bind(this), options)
    }
  } */

  async logout (): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>('logout')
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.logout.bind(this))
    }
  }

  async profile (): Promise<Result<User>> {
    try {
      const data = await this.axios.$get<SfResult<{ user: User }>>('/profile', this.sfAuthConfig)
      return {
        code: 200,
        data: data.result.user
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.profile.bind(this))
    }
  }

  async updateProfile (options: UpdateProfile): Promise<Result<User>> {
    try {
      const data = await this.axios.$put<SfResult<{ user: User }>>('/profile', options, this.sfAuthConfig)
      return {
        code: 200,
        data: data.result.user
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.updateProfile.bind(this), options)
    }
  }

  async changePhoneNumber (payload: ChangePhoneNumber): Promise<Result<string>> {
    try {
      const data = await this.axios.$post<SfResult<{ message: string }>>('/profile/phone', payload, this.sfAuthConfig)

      return {
        code: 200,
        data: data.result.message
      }
    } catch (e) {
      const error = e as ErrorResponse
      return {
        code: 422,
        error: {
          description: error.response.data.error.message
        }
      }
    }
  }

  async confirmPhoneNumber (payload: { code: string }): Promise<Result<string>> {
    try {
      const data = await this.axios.$post<SfResult<{ user: { phoneNumber: string } }>>('/profile/phone/code', payload, this.sfAuthConfig)

      return {
        code: 200,
        data: data.result.user.phoneNumber
      }
    } catch (e) {
      const error = e as ErrorResponse
      return {
        code: 422,
        error: {
          description: error.response.data.error.message
        }
      }
    }
  }

  async checkUserEmail (options: Email): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$get<BooleanStatus>('user/email', {
        params: options
      })
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.checkUserEmail.bind(this), options)
    }
  }

  async checkUserPhone (options: Phone): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$get<BooleanStatus>('user/phone', {
        params: options
      })
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.checkUserPhone.bind(this), options)
    }
  }

  async verificationPhone (payload: VerificationPhoneParams): Promise<Result<VerificationPhoneParams>> {
    try {
      const data = await this.axios.$post<SfResult<VerificationPhoneParams>>('/login/v2', payload, this.sfAuthConfig)
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.verificationPhone.bind(this), payload)
    }
  }

  async smsAuth (options: SmsAuthOptions): Promise<Result<SmsAuthResponse>> {
    try {
      const data = await this.axios.$put<SfResult<SmsAuthResponse>>(
        '/login/code',
        options,
        {
          ...this.sfAuthConfig,
          params: {
            regionId: options.regionId,
            sectionId: options.sectionId
          }
        }
      )

      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.login.bind(this), options)
    }
  }

  /* async verificationPhone (options: VerificationPhone): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<SendAuthCodeResponse>('verification/send', options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.verificationPhone.bind(this), options)
    }
  } */

  async verificationCode (options: VerificationCode): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>('verification', options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.verificationCode.bind(this), options)
    }
  }

  async register (options: RegistrationOptions): Promise<Result<User>> {
    try {
      const data = await this.axios.$post<User>('register', options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.register.bind(this), options)
    }
  }

  async getProduct (options: ProductDetailOptions): Promise<Result<ProductDetail>> {
    try {
      const data = await this.axios.$get<SfResult<{ product: ProductDetail }>>(`products/${options.code}`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result.product
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getProduct.bind(this), options)
    }
  }

  async getOrder (regionId: string, sectionId: string): Promise<Result<Order>> {
    try {
      const data = await this.axios.$get<SfResult<{ order: Order }>>('orders/current', {
        params: {
          regionId,
          sectionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result.order
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getOrder.bind(this), regionId)
    }
  }

  async getOrderByNumber (options: OrderHistoryDetailOptions): Promise<Result<OrderHistoryDetail>> {
    try {
      const data = await this.axios.$get<SfResult<{ order: OrderHistoryDetail}>>(`orders/${options.number}`, {
        ...this.sfAuthConfig,
        params: options
      })
      return {
        code: 200,
        data: data.result.order
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getOrderByNumber.bind(this), options)
    }
  }

  async updateOrder (options: OrderOptions): Promise<Result<Order>> {
    try {
      const data = await this.axios.$post<SfResult<{ order: Order }>>('orders/update', options, {
        ...this.sfAuthConfig,
        params: {
          regionId: options.regionId,
          sectionId: options.sectionId
        }
      })
      return {
        code: 200,
        data: data.result.order
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.updateOrder.bind(this), options)
    }
  }

  async saveOrder (options: OrderOptions): Promise<Result<OrderSaveResult>> {
    try {
      const data = await this.axios.$post<SfResult<{ order: OrderSaveResult }>>('orders', options, {
        params: {
          regionId: options.regionId,
          sectionId: options.sectionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result.order
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.saveOrder.bind(this), options)
    }
  }

  async cancelOrderById (orderId: string, regionId: string, sectionId: string): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<SfResult<BooleanStatus>>(`/orders/cancel/${orderId}`, undefined, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: {
          status: data.status > 200 && data.status < 300
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.cancelOrderById.bind(this), orderId)
    }
  }

  async getFavorites (options: ListOptions): Promise<Result<ListResponseWithIds<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<{ products: Product[], count: number, ids: string[]; filters: FilterItem[] }>>('products/favorite', {
        ...this.sfAuthConfig,
        params: options,
        paramsSerializer: params => qs.stringify(params)
      })
      return {
        code: 200,
        data: {
          items: data.result.products,
          count: data.result.count,
          ids: data.result.ids,
          filters: data.result.filters
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getFavorites.bind(this), options)
    }
  }

  async getSliderFavorites (regionId: String, sectionId: String): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<{ products: Product[], count: number }>>('products/slider/favorite', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
        // paramsSerializer: params => qs.stringify(params)
      })
      return {
        code: 200,
        data: {
          items: data.result.products,
          count: data.result.count
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getSliderFavorites.bind(this), regionId, sectionId)
    }
  }

  async addToFavorite (productId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$post<SfResult<void>>(`products/${productId}/favorite`, {}, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addToFavorite.bind(this), productId)
    }
  }

  async removeFromFavorite (productId: string, regionId: string, sectionId: string): Promise<Result<number>> {
    try {
      const data = await this.axios.$delete<SfResult<{ deletedCount: number }>>(`products/${productId}/favorite`, {
        ...this
          .sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: data.result.deletedCount
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addToFavorite.bind(this), productId)
    }
  }

  async getFavoritesIds (regionId: string, sectionId: string): Promise<Result<{ids: string[]}>> {
    try {
      const data = await this.axios.$get<SfResult<{ids: string[]}>>('products/favorite/ids', {
        params: {
          regionId,
          sectionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getFavoritesIds.bind(this), regionId)
    }
  }

  async changeFavorites (options: FavoritesOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>(`favorites/${options.productId}`, options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.changeFavorites.bind(this), options)
    }
  }

  async createUserProductList (regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$post<SfResult<void>>('user-product-list', {}, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.createUserProductList.bind(this), name)
    }
  }

  async deleteUserProductList (userProductListId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$delete<SfResult<void>>(`user-product-list/${userProductListId}`, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.deleteUserProductList.bind(this), regionId)
    }
  }

  async updateUserProductList (userProductListName: string, userProductListId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$put<SfResult<void>>(`user-product-list/${userProductListId}`, { userProductListName }, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.deleteUserProductList.bind(this), regionId)
    }
  }

  async addToUserProductList (productId: string, userProductListId: string, regionId: string, sectionId: string): Promise<Result> {
    try {
      const data = await this.axios.$post<SfResult<userProductListDataAdd>>(`user-product-list/product/${productId}`, { userProductListId }, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: data.status
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addToUserProductList.bind(this), productId)
    }
  }

  async addedAmountUserProductList (userProductListId: string, productId: string, amount: number, regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$put<SfResult<void>>(`user-product-list/product/${productId}`, { userProductListId, amount }, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addedAmountUserProductList.bind(this), regionId)
    }
  }

  async addProductsToBasket (userProductListItemIds: string[], userProductListId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$post<SfResult<void>>(`user-product-list/cart/${userProductListId}`, { userProductListItemIds }, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addProductsToBasket.bind(this), regionId)
    }
  }

  async removeFromUserProductList (productId: string, userProductListId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$delete<SfResult<void>>(`user-product-list/product/${productId}`, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        },
        data: {
          userProductListId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.removeFromUserProductList.bind(this), regionId)
    }
  }

  async removeItemFromUserProductList (userProductListId: string, userProductListItemId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    try {
      await this.axios.$delete<SfResult<void>>(`user-product-list/item/${userProductListId}`, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        },
        data: {
          userProductListItemId
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.removeFromUserProductList.bind(this), regionId)
    }
  }

  async getUserProductList (regionId: string, sectionId: string): Promise<Result<ListResponseUpl<userProductListDataAll>>> {
    try {
      const data = await this.axios.$get<SfResult<{ userProductListData: userProductListData[]}>>('user-product-list/', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: {
          userProductListDataAll: data.result.userProductListData
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getUserProductList.bind(this), regionId)
    }
  }

  async getUserProductListsLightweight (regionId: string, sectionId: string): Promise<Result<IUserProductListLightweight[]>> {
    try {
      const data = await this.axios.$get<SfResult<{ userProductLists: IUserProductListLightweight[]}>>('user-product-list/lightweight', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: data.result.userProductLists
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getUserProductListsLightweight.bind(this), regionId)
    }
  }

  async getUserProductListById ({ limit, offset, sortType, regionId, sectionId }: getUserListOptions, userProductListId: string): Promise<Result<userProductListData>> {
    try {
      const data = await this.axios.$get<SfResult<{ userProductListData: userProductListData}>>(`/user-product-list/item/${userProductListId}`, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId,
          limit,
          offset,
          sortType
        }
      })
      return {
        code: 200,
        data: {
          userProductList: data.result.userProductListData.userProductList,
          totalSum: data.result.userProductListData.totalSum,
          discount: data.result.userProductListData.discount,
          totalDiscountPrice: data.result.userProductListData.totalDiscountPrice,
          count: data.result.userProductListData.count,
          total: data.result.userProductListData.total
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getUserProductListById.bind(this), regionId)
    }
  }

  async getWaitingList (regionId: string, sectionId: string): Promise<Result<CartItem[]>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<CartItem>>>('/waiting', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: data.result.items
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getWaitingList.bind(this), regionId)
    }
  }

  async getWaitingListIds (): Promise<Result<IdProductWaitingList[]>> {
    try {
      const data = await this.axios.$get<IdProductWaitingList[]>('waiting/id/')
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getWaitingListIds.bind(this))
    }
  }

  async addToWaitingList (productId: string, regionId: string, sectionId: string, amount?: number): Promise<Result<CartItem>> {
    try {
      const data = await this.axios.$post<SfResult<CartItem>>(`waiting/${productId}`, { amount }, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.changeWaitingList.bind(this))
    }
  }

  async removeFromWaitingList (productId: string, regionId: string, sectionId: string): Promise<Result<number>> {
    try {
      const data = await this.axios.$delete<SfResult<{ deletedCount: number }>>(`waiting/${productId}`, {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: data.result.deletedCount
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.changeWaitingList.bind(this))
    }
  }

  async changeWaitingList (options: FavoritesOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>(`waiting/${options.productId}`, options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.changeWaitingList.bind(this), options)
    }
  }

  async searchBar (options: SearchBarOptions, axiosSource?: CancelTokenSource): Promise<Result<SearchBar>> {
    try {
      const config: AxiosRequestConfig = {
        params: options,
        ...this.sfAuthConfig
      }
      if (axiosSource) {
        config.cancelToken = axiosSource.token
      }
      const data = await this.axios.$get<SfResult<SearchBar>>('search/line', config)
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      if (this.axios.isCancel(e)) {
        return {
          code: 480
        }
      } else {
        const error = e as ErrorResponse
        return this.errorManager.check([420], error, this.searchBar.bind(this), options)
      }
    }
  }

  async search (options: SearchOptions): Promise<Result<SearchResult>> {
    try {
      const data = await this.axios.$get<SfResult<SearchResult>>('search', {
        params: options,
        paramsSerializer: params => qs.stringify(params),
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.search.bind(this), options)
    }
  }

  async getArticles (options: ArticleListOptions): Promise<Result<ArticleList>> {
    try {
      const data = await this.axios.$get<SfResult<ArticleList>>('/articles', {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getArticles.bind(this), options)
    }
  }

  async getArticle (options: ArticleDetailOptions): Promise<Result<ArticleDetail>> {
    try {
      const data = await this.axios.$get<SfResult<ArticleDetail>>(`/articles/${options.code}`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getArticle.bind(this), options)
    }
  }

  async getStocks (options: ListOptions): Promise<Result<ListResponse<Stock>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<Stock>>>('promotions', {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getStocks.bind(this), options)
    }
  }

  async getStocksForSlides (regionId: string, sectionId: string): Promise<Result<ListResponse<Stock>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<Stock>>>('promotions/slides', {
        params: { regionId, sectionId },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getStocksForSlides.bind(this))
    }
  }

  async getStock (options: StockDetailOptions): Promise<Result<StockDetail>> {
    try {
      const data = await this.axios.$get<SfResult<StockDetail>>(`promotions/${options.code}`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getStock.bind(this), options)
    }
  }

  async getBanner (options: BannerOptions): Promise<Result<MainSliderItem>> {
    try {
      const data = await this.axios.$get<SfResult<MainSliderItem>>(`banners/${options.code}`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getBanner.bind(this), options)
    }
  }

  async getMarkingAdInfo (creativeTokenId: string): Promise<Result<MarkingAdInfo>> {
    try {
      const data = await this.axios.$get<SfResult<MarkingAdInfo>>(`markingAd/${creativeTokenId}`, {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getMarkingAdInfo.bind(this), creativeTokenId)
    }
  }

  async getStockProducts (options: StockProductOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<Product>>>(`promotions/${options.code}/products`, {
        ...this.sfAuthConfig,
        params: options
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getStockProducts.bind(this), options)
    }
  }

  async getCollectionProductList (options: CollectionProductListOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<CollectionProductListResponse<ListResponse<Product>>>>(`product-list/${options.id}`, {
        ...this.sfAuthConfig,
        params: options
      })
      return {
        code: 200,
        data: data.result.products
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getCollectionProductList.bind(this), options)
    }
  }

  async changePassword (options: ChangePasswordOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>('profile/change-password', options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.changePassword.bind(this), options)
    }
  }

  async getOrders (options: UserOrdersOptions): Promise<Result<ListResponse<OrderHistory>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<OrderHistory>>>('orders', {
        ...this.sfAuthConfig,
        params: options
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getOrders.bind(this), options)
    }
  }

  async getDiseaseSections (): Promise<Result<DiseaseSection[]>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<DiseaseSection>>>('diseases/groups', { ...this.sfAuthConfig })
      return {
        code: 200,
        data: data.result.items
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getDiseaseSections.bind(this))
    }
  }

  async getDiseases (options: DiseaseListOptions): Promise<Result<DiseaseList>> {
    try {
      const data = await this.axios.$get<SfResult<DiseaseList>>(`diseases/groups/${options.code}`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getDiseases.bind(this), options)
    }
  }

  async getDiseasesAll (options: DiseaseListOptions): Promise<Result<DiseaseList>> {
    try {
      const data = await this.axios.$get<SfResult<DiseaseList>>('diseases/', {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getDiseasesAll.bind(this), options)
    }
  }

  async getDisease (code: string): Promise<Result<DiseaseDetail>> {
    try {
      const data = await this.axios.$get<SfResult<{ disease: DiseaseDetail }>>(`diseases/${code}`, { ...this.sfAuthConfig })
      return {
        code: 200,
        data: data.result.disease
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getDisease.bind(this), code)
    }
  }

  async getAddressDadata (options: AddressDadataOptions, axiosSource?: CancelTokenSource): Promise<Result<AddressDadata[]>> {
    try {
      const config: AxiosRequestConfig = {
        params: {
          regionId: options.regionId
        }
      }
      if (axiosSource) {
        config.cancelToken = axiosSource.token
      }
      const data = await this.axios.$post<SfResult<{ suggestions: AddressDadata[] }>>('suggest/address', { ...options }, { ...this.sfAuthConfig, ...config })
      return {
        code: 200,
        data: data.result.suggestions
      }
    } catch (e) {
      if (this.axios.isCancel(e)) {
        return {
          code: 480
        }
      } else {
        const error = e as ErrorResponse
        return this.errorManager.check([420], error, this.getAddressDadata.bind(this), options)
      }
    }
  }

  async getStreetDadata (options: StreetDadataOptions, axiosSource?: CancelTokenSource): Promise<Result<StreetDadata[]>> {
    try {
      const config: AxiosRequestConfig = {
        params: options
      }
      if (axiosSource) {
        config.cancelToken = axiosSource.token
      }
      const data = await this.axios.$get<StreetDadata[]>('dadata/street', config)
      return {
        code: 200,
        data
      }
    } catch (e) {
      if (this.axios.isCancel(e)) {
        return {
          code: 480
        }
      } else {
        const error = e as ErrorResponse
        return this.errorManager.check([420], error, this.getStreetDadata.bind(this), options)
      }
    }
  }

  async getHouseDadata (options: HouseDadataOptions, axiosSource?: CancelTokenSource): Promise<Result<HouseDadata[]>> {
    try {
      const config: AxiosRequestConfig = {
        params: options
      }
      if (axiosSource) {
        config.cancelToken = axiosSource.token
      }
      const data = await this.axios.$get<HouseDadata[]>('dadata/house', config)
      return {
        code: 200,
        data
      }
    } catch (e) {
      if (this.axios.isCancel(e)) {
        return {
          code: 480
        }
      } else {
        const error = e as ErrorResponse
        return this.errorManager.check([420], error, this.getHouseDadata.bind(this), options)
      }
    }
  }

  async resetPasswordByEmail (options: Email): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>('profile/reset-password/email', options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.resetPasswordByEmail.bind(this), options)
    }
  }

  async resetPasswordByPhone (options: PhoneResetPassword): Promise<Result<ResponsePhoneResetPassword>> {
    try {
      const data = await this.axios.$post<ResponsePhoneResetPassword>('profile/reset-password/phone', options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.resetPasswordByPhone.bind(this), options)
    }
  }

  async changePasswordByConfirmCode (options: ChangePasswordCodeOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<BooleanStatus>('profile/change-password/confirm-code', options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.changePasswordByConfirmCode.bind(this), options)
    }
  }

  async getBasketRegionCounts (): Promise<Result<BasketRegionCount[]>> {
    try {
      const data = await this.axios.$get<BasketRegionCount[]>('cart/counts')
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getBasketRegionCounts.bind(this))
    }
  }

  async getPharmacies (): Promise<Result<PharmacyResponse>> {
    try {
      const data = await this.axios.$get<SfResult<PharmacyResponse>>('pharmacy', { ...this.sfAuthConfig })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getPharmacies.bind(this))
    }
  }

  async getPharmacyById (id: string): Promise<Result<Pharmacy>> {
    try {
      const data = await this.axios.$get<SfResult<Pharmacy>>(`pharmacy/${id}`, { ...this.sfAuthConfig })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getPharmacyById.bind(this), id)
    }
  }

  async getThemeList (): Promise<Result<Theme[]>> {
    try {
      const data = await this.axios.$get<SfResult<Theme[]>>('feedback/topics', {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getThemeList.bind(this))
    }
  }

  async sendFeedbackForm (options: FeedbackForm): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<SfResult<BooleanStatus>>('feedback', options, { ...this.sfAuthConfig })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.sendFeedbackForm.bind(this), options)
    }
  }

  async sendOrderRating (options: OrderRatingOptions): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$post<SfResult<BooleanStatus>>(`/orders/${options.orderId}/rating`, options, {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: {
          status: data.status >= 200 && data.status < 400
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.sendOrderRating.bind(this), options)
    }
  }

  async getOrderForRating (regionId: string, sectionId: string): Promise<Result<Order>> {
    try {
      const data = await this.axios.$get<SfResult<{ order: Order }>>('/orders/for-rating', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId
        }
      })
      return {
        code: 200,
        data: data.result.order
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getOrderForRating.bind(this), regionId)
    }
  }

  async getMetaDynamics (options: SeoDynamicOptions): Promise<Result<SeoData>> {
    try {
      const data = await this.axios.$get<SfResult<SeoData>>('seo/dynamics', {
        ...this.sfAuthConfig,
        params: options
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      return {
        code: 500
      }
    }
  }

  async getMetaStatics (options: SeoStaticOptions): Promise<Result<SeoData>> {
    try {
      const data = await this.axios.$get<SeoData>('seo/static', {
        params: options,
        timeout: 1000
      })
      return {
        code: 200,
        data
      }
    } catch (e) {
      return {
        code: 500
      }
    }
  }

  async getCartRecommendation (regionId: string): Promise<Result<Product[]>> {
    try {
      const data = await this.axios.$get<Product[]>('cart/recommendation', {
        params: {
          regionId
        }
      })
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getCartRecommendation.bind(this), regionId)
    }
  }

  async setPaymentOrder (options: OrderPaymentResultOptions): Promise<Result<OrderPaymentResult>> {
    try {
      const data = await this.axios.$post<OrderPaymentResult>(`order/payment/${options.id}`, options)
      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.setPaymentOrder.bind(this), options)
    }
  }

  async addFirebaseToken (token: string): Promise<Result<void>> {
    try {
      await this.axios.$post<never>('/push/subscribe', { pushToken: token }, this.sfAuthConfig)
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.addFirebaseToken.bind(this), token)
    }
  }

  async getTopics (): Promise<Result<Topic[]>> {
    try {
      const data = await this.axios.$get<Topic[]>('firebase/topic')

      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getTopics.bind(this))
    }
  }

  async subscribeToTopic (topicId: number): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$put<BooleanStatus>(`firebase/topic/${topicId}`)

      return {
        code: 200,
        data
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.subscribeToTopic.bind(this))
    }
  }

  async fetchRecipes (options: RecipeListOptions): Promise<Result<RecipeResponse>> {
    try {
      const data = await this.axios.$get<SfResult<RecipeResponse>>('recipe/list', {
        ...this.sfAuthConfig,
        params: {
          status: options.status
        }
      })

      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.fetchRecipes.bind(this))
    }
  }

  async fetchEsiaLink (): Promise<Result<EsiaLinkResponse>> {
    try {
      const data = await this.axios.$get<SfResult<EsiaLinkResponse>>('/esia', this.sfAuthConfig)

      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.fetchEsiaLink.bind(this))
    }
  }

  async fetchRecipeFilterStatuses (): Promise<Result<RecipeStatus[]>> {
    try {
      const data = await this.axios.$get<SfResult<{ statuses: RecipeStatus[] }>>('/recipe/list/statuses', this.sfAuthConfig)
      return {
        code: 200,
        data: data.result.statuses
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.fetchEsiaLink.bind(this))
    }
  }

  async downloadRecipes (): Promise<Result> {
    try {
      await this.axios.$post<SfResult>('/recipe/load', {}, {
        ...this.sfAuthConfig,
        params: {
          await: true
        }
      })
      return {
        code: 200
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.downloadRecipes.bind(this))
    }
  }

  async logoutEsia (): Promise<Result<EsiaLogoutResponse>> {
    try {
      const data = await this.axios.$delete<SfResult<EsiaLogoutResponse>>('/esia/exit', {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.logoutEsia.bind(this))
    }
  }

  async getStaticPage (code: string, regionId: string, sectionId: string): Promise<Result<StaticPage>> {
    try {
      const response = await this.axios.$get<SfResult<StaticPage>>(`/static-pages/${code}`, {
        params: {
          regionId,
          sectionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getStaticPage.bind(this))
    }
  }

  async getStaticPages (regionId: string, sectionId: string): Promise<Result<StaticPage[]>> {
    try {
      const response = await this.axios.$get<SfResult<StaticPage[]>>('/static-pages/', {
        params: {
          regionId,
          sectionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getStaticPages.bind(this))
    }
  }

  async getCodeGenerationCooldownTime (): Promise<Result<number>> {
    try {
      const response = await this.axios.$get<SfResult<number>>('/login/code-generation-cooldown-time', {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getCodeGenerationCooldownTime.bind(this))
    }
  }

  async getPickPointsForOrder (regionId: string, sectionId: string, basketNomenclatureIds?: string[], limit?: number, offset?: number): Promise<Result<ListResponse<OrderPickpoint>>> {
    try {
      const response = await this.axios.$get<SfResult<ListResponse<OrderPickpoint>>>('/orders/pick-points', {
        ...this.sfAuthConfig,
        params: {
          regionId,
          sectionId,
          basketNomenclatureIds,
          limit,
          offset
        }
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getPickPointsForOrder.bind(this))
    }
  }

  async getMetrikaCodes (): Promise<Result<{ codes: MetrikaCode[] }>> {
    try {
      const response = await this.axios.$get<SfResult<{ codes: MetrikaCode[]}>>('/seo/metrika-codes', {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getMetrikaCodes.bind(this))
    }
  }

  async getLicenses (regionId: string, sectionId: string): Promise<Result<License[]>> {
    try {
      const response = await this.axios.$get<SfResult<License[]>>('/static-pages/licenses', {
        params: {
          regionId,
          sectionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getLicenses.bind(this))
    }
  }

  async getUserDefaultAddress (regionId: string): Promise<Result<UserDefaultAddress>> {
    try {
      const response = await this.axios.$get<SfResult<UserDefaultAddress>>('/profile/delivery-address', {
        params: {
          regionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getUserDefaultAddress.bind(this))
    }
  }

  async setUserDefaultAddress (regionId: string, data: UserDefaultAddress): Promise<Result<UserDefaultAddress>> {
    try {
      const response = await this.axios.$put<SfResult<UserDefaultAddress>>('/profile/delivery-address', data, {
        params: {
          regionId
        },
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.setUserDefaultAddress.bind(this))
    }
  }

  async getSiteSetting (): Promise<Result<SiteSetting>> {
    try {
      const response = await this.axios.$get<SfResult<SiteSetting>>('/settings', {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: response.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.setUserDefaultAddress.bind(this))
    }
  }

  async setUserPushPermission (value: boolean, pushToken: string): Promise<Result<BooleanStatus>> {
    try {
      await this.axios.$put<SfResult<BooleanStatus>>('/push/permission', {
        allow: value,
        pushToken
      }, {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: {
          status: true
        }
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.setUserPushPermission.bind(this))
    }
  }

  async getRecommendedProducts (options: ProductDetailOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<Product>>>(`products/${options.code}/recommended`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getRecommendedProducts.bind(this), options)
    }
  }

  async getAnalogs (options: ProductDetailOptions): Promise<Result<ListResponse<Product>>> {
    try {
      const data = await this.axios.$get<SfResult<ListResponse<Product>>>(`products/${options.code}/analogs`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getAnalogs.bind(this), options)
    }
  }

  async fetchCategoryFilters (options: Omit<CatalogOptions, 'limit' | 'offset' | 'filter'>): Promise<Result<{ filters: [] }>> {
    try {
      const data = await this.axios.$get<SfResult<{ filters: [] }>>(`catalog/sections/${options.id}/filters`, {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.fetchCategoryFilters.bind(this), options)
    }
  }

  async fetchCategoriesByParentId (option: CategoriesOptions): Promise<Result<CategoriesResponse>> {
    try {
      const data = await this.axios.$get<SfResult<CategoriesResponse>>(`catalog/categories/${option.id}`, {
        ...this.sfAuthConfig,
        params: {
          sectionId: option.sectionId
        }
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.fetchCategoriesByParentId.bind(this))
    }
  }

  async fetchCategoriesByLetter (option: CategoriesOptions): Promise<Result<CategoriesResponse>> {
    try {
      const data = await this.axios.$get<SfResult<CategoriesResponse>>(`catalog/categories/${option.id}/group`, {
        params: option,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.fetchCategoriesByLetter.bind(this))
    }
  }

  async getDeliveryAvailable (regionId: string, address: AddressDadata): Promise<Result<BooleanStatus>> {
    try {
      const data = await this.axios.$get<SfResult<BooleanStatus>>(`suggest/address/${regionId}`, {
        params: address,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: data.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.fetchCategoriesByLetter.bind(this))
    }
  }

  async getDpdStatus (): Promise<Result<Boolean>> {
    try {
      const status = await this.axios.$get<SfResult<Boolean>>('integrations/dpd-status', {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: status.result
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getDpdStatus.bind(this))
    }
  }

  async getTagsCloud (categoryId: string, regionId: string, sectionId: string): Promise<Result<ITagsCloudElement[]>> {
    try {
      const status = await this.axios.$get<SfResult<{items: ITagsCloudElement[]}>>(`landing-page?categoryId=${categoryId}&regionId=${regionId}&sectionId=${sectionId}`, {
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: status.result.items
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getTagsCloud.bind(this))
    }
  }

  async getLandingPage (options: ILandingPageOptions): Promise<Result<ILandingPage>> {
    try {
      const status = await this.axios.$get<SfResult<{landingPage: ILandingPage}>>('landing-page/page', {
        params: { ...options },
        paramsSerializer: params => qs.stringify(params),
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: status.result.landingPage
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getLandingPage.bind(this))
    }
  }

  async getLandingPageFilters (options: ILandingPageOptions): Promise<Result<FilterItem[]>> {
    try {
      const status = await this.axios.$get<SfResult<{filters: FilterItem[]}>>('landing-page/filters', {
        params: options,
        ...this.sfAuthConfig
      })
      return {
        code: 200,
        data: status.result.filters
      }
    } catch (e) {
      const error = e as ErrorResponse
      return this.errorManager.check([420], error, this.getLandingPageFilters.bind(this))
    }
  }
}
