import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Route } from "vue-router";
import UnknownPageType from "~/types/enum/UnknownPageType";
import SectionHelper from "~/utils/sectionHelper";
import config from "~/utils/config";
import loc from "~/utils/loc";
import SeoHelper from "~/utils/seoHelper";
import s from "~/utils/s";
import { SfSection } from "~/types/api/SfSection";

interface CheckPageOptions {
  route?: Route;
  isRedirect?: boolean;
}

@Module({
  name: "unknownPage",
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _pageType: UnknownPageType = UnknownPageType.NotFound;
  _showSticky: boolean = false;

  @Mutation
  setPageType(type: UnknownPageType) {
    this._pageType = type;
  }

  @Mutation
  setShowSticky(showSticky: boolean) {
    this._showSticky = showSticky;
  }

  @Action({ rawError: config.rawError })
  changeShowSticky(showSticky: boolean) {
    this.setShowSticky(showSticky);
  }

  @Action({ rawError: config.rawError })
  private async searchCategory(route: Route): Promise<boolean> {
    const path = route.path;
    const sectionId = new SectionHelper(
      s(this.store).general.sections,
      this.store
    ).getSectionIdByPath(path);
    if (sectionId) {
      this.setPageType(UnknownPageType.Category);
      await s(this.store).categoryPage.loadCategoryFilters({
        categoryId: sectionId,
        limit: 100
      });
      await s(this.store).categoryPage.loadCategory({
        route,
        sectionId
      });
      await s(this.store).landingPage.loadTagsCloud(sectionId);
      return true;
    }
    return false;
  }

  @Action({ rawError: config.rawError })
  private async searchProduct(route: Route): Promise<boolean> {
    const path = route.path;
    const folders = path.split("/");
    folders.pop();
    const productCode = folders.pop();
    const regionId = s(this.store).regions.regionId ?? "";
    const sectionId = s(this.store).regions.sectionId ?? "";
    const result = await this.store.$api.getProduct({
      regionId,
      sectionId,
      code: productCode ?? ""
    });
    if (result.code === 200 && result.data) {
      this.setPageType(UnknownPageType.Product);
      await s(this.store).productPage.loadProduct(result.data);
      return true;
    } else if (result.code === 301) {
      this.setPageType(UnknownPageType.Redirect);
      return false;
    }
    return false;
  }

  @Action({ rawError: config.rawError })
  private async searchLandingPage(route: Route): Promise<boolean> {
    const path = route.path;
    const landingPageUrl = path
      .split("?")[0]
      .split("/")
      .filter(e => e !== "")
      .reverse()[0];
    const regionId = s(this.store).regions.regionId ?? "";
    const sectionId = s(this.store).regions.sectionId ?? "";
    if (landingPageUrl) {
      this.setPageType(UnknownPageType.LandingPage);
      const options = {
        landingPageUrl,
        regionId,
        sectionId,
        limit: 12,
        offset: 0
      };
      await s(this.store).landingPage.loadLandingPageFilters({
        landingPageUrl,
        regionId,
        sectionId
      });
      await s(this.store).landingPage.loadLandingPage(route);
      if (!s(this.store).landingPage.landingPage.isActive) {
        this.setPageType(UnknownPageType.Redirect);
        return false;
      }
      const targetSection = s(this.store).general.sections.find(
        (section: SfSection) =>
          section.id === s(this.store).landingPage.landingPage.categoryId
      );
      if (targetSection) {
        const helper = new SectionHelper(
          s(this.store).general.sections,
          this.store
        );
        s(this.store).categoryPage.setPathSections(
          helper.getSectionsPath(targetSection.id)
        );
      }
      return true;
    }
    return true;
  }

  @Action({ rawError: config.rawError })
  async checkPage(options?: CheckPageOptions) {
    let route = this.store.$router.currentRoute;
    if (options?.route) {
      route = options.route;
    }

    const isReviews = route.path.match(/\/reviews\/?$/i);
    if (isReviews) {
      this.setPageType(UnknownPageType.Reviews);
      return;
    }

    const seoHelper = new SeoHelper(route.name ?? "index", this.store);

    const isSection = await this.searchCategory(route);
    if (isSection) {
      await seoHelper.load();
      return;
    }

    const isProduct = await this.searchProduct(route);
    if (isProduct) {
      await seoHelper.load();
      return;
    }

    const isLandingPage = await this.searchLandingPage(route);
    if (isLandingPage) {
      await seoHelper.load();
      return;
    }

    if (this.pageType === UnknownPageType.Redirect) {
      s(this.store).general.redirectMainPage();
      return;
    }

    this.setPageType(UnknownPageType.NotFound);
    if (options?.isRedirect === false) {
      s(this.store).general.redirectNotFound();
    } else {
      await this.store.$router.push(loc(this.store, { name: "catalog" }));
    }
  }

  get pageType(): UnknownPageType {
    return this._pageType;
  }

  get showSticky(): boolean {
    return this._showSticky;
  }
}
