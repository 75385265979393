/* eslint-disable @typescript-eslint/no-unused-vars */
import DataForResult from '../../types/DataForResult'
import Api, { ChangePhoneNumber } from './Api'
import Result from '~/types/api/Result'
import ListOptions from '~/types/api/options/ListOptions'
import ListResponse, { ListResponseUpl, ListResponseWithIds } from '~/types/api/ListResponse'
import Product from '~/types/api/Product'
import { Region } from '~/types/api/Region'
import { MainSliderItem } from '~/types/api/MainSliderItem'
import { MainSliderAdvertisementItem } from '~/types/api/MainSliderAdvertisementItem'
import Token from '~/types/api/Token'
import CatalogOptions from '~/types/api/options/CatalogOptions'
import CartOptions from '~/types/api/options/CartOptions'
import Cart from '~/types/api/Cart'
import AuthorizationOptions from '~/types/api/options/AuthorizationOptions'
import User from '~/types/api/User'
import BooleanStatus from '~/types/api/BooleanStatus'
import Email from '~/types/api/Email'
import Phone from '~/types/api/Phone'
import VerificationCode from '~/types/api/VerificationCode'
import RegistrationOptions from '~/types/api/options/RegistrationOptions'
import ProductDetail from '~/types/api/ProductDetail'
import ProductDetailOptions from '~/types/api/options/ProductDetailOptions'
import Order from '~/types/api/order/Order'
import FavoritesOptions from '~/types/api/options/FavoritesOptions'
import SearchBarOptions from '~/types/api/options/SearchBarOptions'
import SearchBar from '~/types/api/search/SearchBar'
import SearchOptions from '~/types/api/options/SearchOptions'
import SearchResult from '~/types/api/search/SearchResult'
import ArticleListOptions from '~/types/api/options/ArticleListOptions'
import ArticleList from '~/types/api/news/ArticleList'
import ArticleDetailOptions from '~/types/api/options/ArticleDetailOptions'
import ArticleDetail from '~/types/api/news/ArticleDetail'
import Stock from '~/types/api/stock/Stock'
import StockDetailOptions from '~/types/api/options/StockDetailOptions'
import StockProductOptions from '~/types/api/options/StockProductOptions'
import StockDetail from '~/types/api/stock/StockDetail'
import UpdateProfile from '~/types/api/options/UpdateProfile'
import ChangePasswordOptions from '~/types/api/options/ChangePasswordOptions'
import OrderHistory from '~/types/api/orderHistory/OrderHistory'
import DiseaseSection from '~/types/api/disease/DiseaseSection'
import DiseaseListOptions from '~/types/api/options/DiseaseListOptions'
import DiseaseList from '~/types/api/disease/DiseaseList'
import DiseaseDetail from '~/types/api/disease/DiseaseDetail'
import OrderOptions from '~/types/api/order/OrderOptions'
import OrderSaveResult from '~/types/api/order/OrderSaveResult'
import AddressDadataOptions from '~/types/api/order/AddressDadataOptions'
import AddressDadata from '~/types/api/order/AddressDadata'
import StreetDadataOptions from '~/types/api/order/StreetDadataOptions'
import StreetDadata from '~/types/api/order/StreetDadata'
import PhoneResetPassword from '~/types/api/PhoneResetPassword'
import ResponsePhoneResetPassword from '~/types/api/ResponsePhoneResetPassword'
import ChangePasswordCodeOptions from '~/types/api/options/ChangePasswordCodeOptions'
import ProductViewedOptions from '~/types/api/options/ProductViewedOptions'
import ReviewOptions from '~/types/api/options/ReviewOptions'
import ReviewByUserOptions from '~/types/api/options/ReviewByUserOptions'
import ReviewListOptions from '~/types/api/options/ReviewListOptions'
import BasketRegionCount from '~/types/api/BasketRegionCount'
import Theme from '~/types/api/Theme'
import FeedbackForm from '~/types/api/options/FeedbackForm'
import HouseDadataOptions from '~/types/api/order/HouseDadataOptions'
import HouseDadata from '~/types/api/order/HouseDadata'
import PharmacyResponse from '~/types/api/pharmacy/PharmacyResponse'
import Pharmacy from '~/types/api/pharmacy/Pharmacy'
import OrderHistoryDetailOptions from '~/types/api/options/OrderHistoryDetailOptions'
import OrderHistoryDetail from '~/types/api/orderHistory/OrderHistoryDetail'
import SeoDynamicOptions from '~/types/api/seo/SeoDynamicOptions'
import SeoStaticOptions from '~/types/api/seo/SeoStaticOptions'
import SeoData from '~/types/seo/SeoData'
import OrderPaymentResultOptions from '~/types/api/options/OrderPaymentResultOptions'
import OrderPaymentResult from '~/types/api/OrderPaymentResult'
import UserOrdersOptions from '~/types/api/options/UserOrdersOptions'
import PartnerCardBlockOptions from '~/types/api/options/PartnerCardBlockOptions'
import SeoTextBlockOptions from '~/types/api/options/SeoTextBlockOptions'
import SeoTextBlock from '~/types/api/SeoTextBlock'
import HelpPageText from '~/types/api/HelpPageText'
import HelpPageTextOptions from '~/types/api/options/HelpPageTextOptions'
import TypeDevice from '~/types/enum/TypeDevice'
import Review from '~/types/api/Review'
import IdProductWaitingList from '~/types/api/IdProductWaitingList'
import OrderRatingOptions from '~/types/api/options/OrderRatingOptions'
import Topic from '~/types/api/Topic'
import Preset from '~/types/api/Preset'
import PresetCategory from '~/types/api/PresetCategory'
import PresetOptions from '~/types/api/options/PresetOptions'
import SmsAuthOptions from '~/types/api/options/SmsAuthOptions'
import { VerificationPhoneParams } from '~/types/api/VerificationPhoneParams'
import { CartItem } from '~/types/api/SfCart'
import { SmsAuthResponse, Tokens } from '~/utils/api/ServerApi'
import { SfSection } from '~/types/api/SfSection'
import { SfCatalogCategory } from '~/types/SfCatalogCategory'
import { EsiaLinkResponse } from '~/types/api/recipe/EsiaLinkResponse'
import { RecipeStatus } from '~/types/api/recipe/RecipeStatus'
import { RecipeResponse } from '~/types/api/recipe/RecipeResponse'
import { EsiaLogoutResponse } from '~/types/api/recipe/EsiaLogoutResponse'
import { RecipeListOptions } from '~/types/api/recipe/RecipeListOptions'
import StaticPage from '~/types/api/StaticPage'
import OrderCopyOptions from '~/types/api/options/OrderCopyOptions'
import OrderPickpoint from '~/types/api/order/OrderPickpoint'
import { MetrikaCode } from '~/types/api/MetrikaCode'
import ProductCardCommercialOptions from '~/types/api/options/ProductCardCommercialOptions'
import License from '~/types/api/License'
import { UserDefaultAddress } from '~/types/api/UserDefaultAddress'
import { SiteSetting } from '~/types/api/SiteSetting'
import UserProductList, { IUserProductListLightweight, getUserListOptions, userProductListData, userProductListDataAdd } from '~/types/api/AidKit'
import CollectionProductListOptions from '~/types/api/options/CollectionProductListOptions'
import BannerOptions from '~/types/api/options/BannerOptions'
import { MarkingAdInfo } from '~/types/api/MarkingAdTypes'
import { CategoriesOptions } from '~/types/api/categories/CategoriesOptions'
import CategoriesResponse from '~/types/api/categories/CategoriesResponse'
import SliderProducts from '~/types/api/sliderProducts'
import ITagsCloudElement from '~/types/api/TagsCloudElement'
import ILandingPage, { ILandingPageOptions } from '~/types/api/LandingPage'
import FilterItem from '~/types/api/filter/FilterItem'

export default class implements Api {
  private debugDelay = 300

  setTypeDevice (_: TypeDevice): void {

  }

  setConstructionHeader (_value: string) {

  }

  setToken (_token: string): void {
  }

  getCatalogSections (_regionId: string): Promise<Result<SfSection[]>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/Sections')).default
        }
      ), this.debugDelay)
    })
  }

  getCatalogResult (options: {categoryId: string, regionId: string, sectionId: string}): Promise<Result<DataForResult>> {
    throw new Error('Method is not implemented')
  }

  catalogResultAddToCart (productIds: string[], regionId: string, sectionId: string): Promise<Result> {
    throw new Error('Method is not implemented')
  }

  addToFavorite (_productId: string): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  getNamesForCertificate (codes: string[], regionsId: string, sectionId: string): Promise<Result> {
    throw new Error('Method is not implemented')
  }

  addToWaitingList (_productId: string): Promise<Result<CartItem>> {
    throw new Error('Method is not implemented')
  }

  addToUserProductList (_productId: string): Promise<Result<userProductListDataAdd>> {
    throw new Error('Method is not implemented')
  }

  getUserProductList (): Promise<Result<ListResponseUpl<UserProductList>>> {
    throw new Error('Method is not implemented')
  }

  getUserProductListsLightweight (): Promise<Result<IUserProductListLightweight[]>> {
    throw new Error('Method is not implemented')
  }

  getUserProductListById (_userProductListId: getUserListOptions): Promise<Result<userProductListData>> {
    throw new Error('Method is not implemented')
  }

  addProductsToBasket (userProductListItemIds: string[], userProductListId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  removeFromUserProductList (productId: string, userProductListId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  removeItemFromUserProductList (productId: string, userProductListItemId: string, regionId: string, sectionId: string): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  createUserProductList (): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  updateUserProductList (): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  deleteUserProductList (_userProductListId: string): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  addedAmountUserProductList (userProductListId: string): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  changePhoneNumber (_payload: ChangePhoneNumber): Promise<Result<string>> {
    throw new Error('Method is not implemented')
  }

  confirmPhoneNumber (_payload: { code: string }): Promise<Result<string>> {
    throw new Error('Method is not implemented')
  }

  getAuthorizeToken (): Promise<Result<Token>> {
    throw new Error('Method is not implemented')
  }

  getProducts (): Promise<Result<ListResponse<Product>>> {
    throw new Error('Method is not implemented')
  }

  removeCartRows (rowIds: string[]): Promise<Result<Cart>> {
    throw new Error('Method is not implemented')
  }

  removeFromFavorite (productId: string): Promise<Result<number>> {
    throw new Error('Method is not implemented')
  }

  removeFromWaitingList (productId: string): Promise<Result<number>> {
    throw new Error('Method is not implemented')
  }

  setRefreshToken (token: string): void {
    throw new Error('Method is not implemented')
  }

  setSfToken (token: string): void {
    throw new Error('Method is not implemented')
  }

  getMarkingAdInfo (creativeToken: string): Promise<Result<MarkingAdInfo>> {
    throw new Error('Method is not implemented')
  }

  refreshToken (): Promise<Result<Tokens>> {
    return Promise.resolve({
      code: 200,
      data: {
        jwt: '1234567890876543212345678',
        rt: '987654323456789098765'
      }
    })
  }

  getCatalogSection (options: CatalogOptions): Promise<Result<SfCatalogCategory>> {
    throw new Error('Method is not implemented')
  }

  getCountItemsCatalogSection (_options: CatalogOptions): Promise<Result<number>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: 85
        }
      ), 300)
    })
  }

  getProductPromotions (_options: ListOptions): Promise<Result<ListResponse<Product>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/Products')).default(10)
          }
        }
      ), 500)
    })
  }

  getProductNovelties (options: ListOptions): Promise<Result<ListResponse<Product>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/Products')).default(10)
          }
        }
      ), 500)
    })
  }

  getProductPopular (_options: ListOptions): Promise<Result<ListResponse<Product>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/Products')).default(12)
          }
        }
      ), 500)
    })
  }

  addReview (options: ReviewOptions): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  getReviews (options: ReviewListOptions): Promise<Result<ListResponse<Review>>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            count: options.limit,
            items: []/* (await import('./debug/Reviews')).default(options.limit) */
          }
        }
      ), 500)
    })
  }

  getReviewByUser (_options: ReviewByUserOptions): Promise<Result<Review>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            rating: 3,
            text: 'Lorem Ipsum'
          }
        }
      ), 500)
    })
  }

  getPresetList (): Promise<Result<Preset[]>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/Preset')).default(5)
        }
      ), 500)
    })
  }

  getPresetProducts (): Promise<Result<PresetCategory>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            id: '1',
            parentId: '0',
            code: 'test',
            title: 'Растворы для парентерального питания',
            count: 5,
            availableCount: 3,
            items: (await import('./debug/Products')).default(5)
          }
        }
      ), 300)
    })
  }

  addCartPreset (options: PresetOptions): Promise<Result<Cart>> {
    return this.getCart('2')
  }

  getProductViewed (options: ListOptions): Promise<Result<ListResponse<Product>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: options.limit,
            items: (await import('./debug/Products')).default(options.limit)
          }
        }
      ), 500)
    })
  }

  addProductViewed (_options: ProductViewedOptions): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  getSeoTextBlock (_options: SeoTextBlockOptions): Promise<Result<SeoTextBlock>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/SeoTextBlock')).default
        }
      ), 500)
    })
  }

  getHelpPageText (_options: HelpPageTextOptions): Promise<Result<HelpPageText>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/HelpPageText')).default
        }
      ), 500)
    })
  }

  getRegions (): Promise<Result<Region[]>> {
    throw new Error('Method is not implemented')
  }

  updateReview (options: ReviewOptions): Promise<Result<BooleanStatus>> {
    throw new Error('Method is not implemented')
  }

  /* getRegions (): Promise<Result<Region[]>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/Regions')).default
        }
      ), 500)
    })
  } */

  getMainSliderItems (_regionId: string): Promise<Result<MainSliderItem[]>> {
    throw new Error('Method is not implemented')
  }

  getMainSliderAdvertisementItems (_regionId: string): Promise<Result<MainSliderAdvertisementItem[]>> {
    throw new Error('Method is not implemented')
  }

  getToken (): Promise<Result<Token>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            token: 'test33'
          }
        }
      ), 100)
    })
  }

  getCart (_regionId: string): Promise<Result<Cart>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            count: 0,
            items: [],
            message: '',
            price: 0,
            discountPrice: 0,
            basePrice: 0
          }
          // (await import('./debug/Cart')).default(5)
        }
      ), 200)
    })
  }

  addCart (_options: CartOptions): Promise<Result<Cart>> {
    return this.getCart('5')
  }

  copyOrder (_options: OrderCopyOptions): Promise<Result<Cart>> {
    throw new Error('Method is not implemented')
  }

  changeCart (_options: CartOptions): Promise<Result<Cart>> {
    return this.getCart('5')
  }

  removeCart (_options: CartOptions): Promise<Result<Cart>> {
    return this.getCart('5')
  }

  login (_options: AuthorizationOptions): Promise<Result<User>> {
    return this.profile()
  }

  /* smsAuth (_options: SmsAuthOptions): Promise<Result<User>> {
    return this.profile()
  } */

  smsAuth (options: SmsAuthOptions): Promise<Result<SmsAuthResponse>> {
    throw new Error('Method is not implemented')
  }

  logout (): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 300)
    })
  }

  profile (): Promise<Result<User>> {
    throw new Error('Method is not implemented')
  }

  updateProfile (_options: UpdateProfile): Promise<Result<User>> {
    return this.profile()
  }

  checkUserEmail (_options: Email): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 300)
    })
  }

  checkUserPhone (_options: Phone): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 300)
    })
  }

  verificationPhone (_options: VerificationPhoneParams): Promise<Result<VerificationPhoneParams>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            phone: '79999999999'
          }
        }
      ), 300)
    })
  }

  verificationCode (_options: VerificationCode): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 300)
    })
  }

  register (_options: RegistrationOptions): Promise<Result<User>> {
    return this.profile()
  }

  getProduct (_options: ProductDetailOptions): Promise<Result<ProductDetail>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: await (await import('./debug/ProductDetailMuck')).default()
        }
      ), 300)
    })
  }

  getOrder (_regionId: string): Promise<Result<Order>> {
    throw new Error('Method not implemented')
  }

  getOrderByNumber (_options: OrderHistoryDetailOptions): Promise<Result<OrderHistoryDetail>> {
    throw new Error('Method not implemented')
  }

  updateOrder (_options: OrderOptions): Promise<Result<Order>> {
    throw new Error('Method not implemented')
  }

  saveOrder (_options: OrderOptions): Promise<Result<OrderSaveResult>> {
    throw new Error('Method not implemented')
  }

  cancelOrderById (_orderId: string): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  /* getFavorites (_options: ListOptions): Promise<Result<ListResponse<Product>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/Products')).default(10)
          }
        }
      ), 500)
    })
  }

  getFavoritesIds (_regionId: number): Promise<Result<number[]>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: [1, 2]
        }
      ), 500)
    })
  } */

  getFavorites (): Promise<Result<ListResponseWithIds<Product>>> {
    throw new Error('Method is not implemented')
  }

  getFavoritesIds (regionId: string, sectionId: string): Promise<Result<{ ids: string[] }>> {
    throw new Error('Method is not implemented')
  }

  changeFavorites (_options: FavoritesOptions): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  getSliderFavorites (): Promise<Result<ListResponse<Product>>> {
    throw new Error('Method is not implemented')
  }

  /* getWaitingList (_regionId: number): Promise<Result<ProductCart[]>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: await (await import('./debug/ProductsCart')).default()
        }
      ), 500)
    })
  } */

  getWaitingList (regionId: string): Promise<Result<CartItem[]>> {
    throw new Error('Method is not implemented')
  }

  getWaitingListIds (): Promise<Result<IdProductWaitingList[]>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: [{ id: '908347', regionId: '2' }, { id: '134613', regionId: '3' }]
        }
      ), 500)
    })
  }

  changeWaitingList (_options: FavoritesOptions): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  searchBar (_options: SearchBarOptions): Promise<Result<SearchBar>> {
    throw new Error('Method not implemented.')
  }

  search (options: SearchOptions): Promise<Result<SearchResult>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: await (await import('./debug/Search')).default(options.limit)
        }
      ), 500)
    })
  }

  getArticles (options: ArticleListOptions): Promise<Result<ArticleList>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/ArticleItems')).default(options.limit),
            categories: (await import('./debug/ArticleCategories')).default
          }
        }
      ), 500)
    })
  }

  getArticle (_options: ArticleDetailOptions): Promise<Result<ArticleDetail>> {
    throw new Error('Method not implemented.')
  }

  getStocks (options: ListOptions): Promise<Result<ListResponse<Stock>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/StockList')).default(options.limit)
          }
        }
      ), 500)
    })
  }

  getStocksForSlides (regionId: string, sectionId: string): Promise<Result<ListResponse<Stock>>> {
    throw new Error('Method not implemented.')
  }

  getStock (_options: StockDetailOptions): Promise<Result<StockDetail>> {
    throw new Error('Method not implemented.')
  }

  getBanner (_options: BannerOptions): Promise<Result<MainSliderItem>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/Banner')).default()
        }
      ), 500)
    })
  }

  getCollectionProductList (_options: CollectionProductListOptions): Promise<Result<ListResponse<Product>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/Products')).default(8)
          }
        }
      ), 500)
    })
  }

  getStockProducts (_options: StockProductOptions): Promise<Result<ListResponse<Product>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/Products')).default(8)
          }
        }
      ), 500)
    })
  }

  changePassword (_options: ChangePasswordOptions): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  getOrders (_options: UserOrdersOptions): Promise<Result<ListResponse<OrderHistory>>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 20,
            items: (await import('./debug/OrderList')).default(20)
          }
        }
      ), 500)
    })
  }

  getDiseaseSections (): Promise<Result<DiseaseSection[]>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/DiseaseSections')).default
        }
      ), 500)
    })
  }

  getDiseases (options: DiseaseListOptions): Promise<Result<DiseaseList>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: {
            count: 139,
            items: (await import('./debug/DiseaseItems')).default(options.limit),
            filterFirstLetters: ['А', 'Г', 'В']
          }
        }
      ), 500)
    })
  }

  getDiseasesAll (options: DiseaseListOptions): Promise<Result<DiseaseList>> {
    return this.getDiseases(options)
  }

  getDisease (_code: string): Promise<Result<DiseaseDetail>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/DiseaseDetail')).default()
        }
      ), 500)
    })
  }

  getAddressDadata (_options: AddressDadataOptions): Promise<Result<AddressDadata[]>> {
    throw new Error('Method not implemented.')
  }

  getStreetDadata (_options: StreetDadataOptions): Promise<Result<StreetDadata[]>> {
    throw new Error('Method not implemented.')
  }

  getHouseDadata (_options: HouseDadataOptions): Promise<Result<HouseDadata[]>> {
    throw new Error('Method not implemented.')
  }

  resetPasswordByEmail (_options: Email): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  resetPasswordByPhone (_options: PhoneResetPassword): Promise<Result<ResponsePhoneResetPassword>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true,
            confirmCode: 'sdad'
          }
        }
      ), 500)
    })
  }

  changePasswordByConfirmCode (_options: ChangePasswordCodeOptions): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 500)
    })
  }

  getBasketRegionCounts (): Promise<Result<BasketRegionCount[]>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: [
            {
              directory: 'apteki',
              count: 5
            },
            {
              directory: 'ortopedia',
              count: 25
            }
          ]
        }
      ), 500)
    })
  }

  getPharmacies (): Promise<Result<PharmacyResponse>> {
    throw new Error('Method not implemented.')
  }

  getPharmacyById (_id: string): Promise<Result<Pharmacy>> {
    throw new Error('Method not implemented.')
  }

  getThemeList (): Promise<Result<Theme[]>> {
    throw new Error('Method not implemented. ')
  }

  sendFeedbackForm (_options: FeedbackForm): Promise<Result<BooleanStatus>> {
    throw new Error('Method not implemented. ')
  }

  sendOrderRating (_options: OrderRatingOptions): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 300)
    })
  }

  getOrderForRating (regionId: string, sectionId: string): Promise<Result<Order>> {
    throw new Error('Method is not implemented')
  }

  getPartnerCardBlock (options: PartnerCardBlockOptions): Promise<Result<MainSliderItem[]>> {
    throw new Error('Method is not implemented')
  }

  getMetaDynamics (_options: SeoDynamicOptions): Promise<Result<SeoData>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            imgAlt: '',
            imgTitle: '',
            title: 'title' + Math.random() * (100000 - 0) + 0,
            description: 'description' + Math.random() * (100000 - 0) + 0,
            keywords: 'keywords' + Math.random() * (100000 - 0) + 0
          }
        }
      ), 500)
    })
  }

  getMetaStatics (_options: SeoStaticOptions): Promise<Result<SeoData>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            imgAlt: '',
            imgTitle: '',
            title: 'title' + Math.floor(Math.random() * Math.floor(1000)),
            description: 'description' + Math.floor(Math.random() * Math.floor(1000)),
            keywords: 'keywords' + Math.floor(Math.random() * Math.floor(1000))
          }
        }
      ), 500)
    })
  }

  getCartRecommendation (_regionId: string): Promise<Result<Product[]>> {
    return new Promise((resolve) => {
      setTimeout(async () => resolve(
        {
          code: 200,
          data: (await import('./debug/Products')).default(12)
        }
      ), 500)
    })
  }

  setPaymentOrder (_options: OrderPaymentResultOptions): Promise<Result<OrderPaymentResult>> {
    throw new Error('Method not implemented.')
  }

  /* addFirebaseToken (token: string): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 300)
    })
  } */

  addFirebaseToken (token: string): Promise<Result<void>> {
    throw new Error('Method is not implemented')
  }

  getTopics (): Promise<Result<Topic[]>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: []
        }
      ), 300)
    })
  }

  subscribeToTopic (topicId: number): Promise<Result<BooleanStatus>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(
        {
          code: 200,
          data: {
            status: true
          }
        }
      ), 300)
    })
  }

  downloadRecipes (): Promise<Result> {
    return Promise.resolve({
      code: 200
    })
  }

  fetchEsiaLink (): Promise<Result<EsiaLinkResponse>> {
    return Promise.resolve({
      code: 200
    })
  }

  fetchRecipeFilterStatuses (): Promise<Result<RecipeStatus[]>> {
    return Promise.resolve({
      code: 200
    })
  }

  fetchRecipes (options: RecipeListOptions): Promise<Result<RecipeResponse>> {
    return Promise.resolve({
      code: 200
    })
  }

  logoutEsia (): Promise<Result<EsiaLogoutResponse>> {
    return Promise.resolve({
      code: 200
    })
  }

  getStaticPage (code: string, regionId: string, sectionId: string): Promise<Result<StaticPage>> {
    throw new Error('Method is not implemented')
  }

  getStaticPages (regionId: string, sectionId: string): Promise<Result<StaticPage[]>> {
    throw new Error('Method is not implemented')
  }

  getCodeGenerationCooldownTime (): Promise<Result<number>> {
    throw new Error('Method is not implemented')
  }

  getPickPointsForOrder (regionId: string): Promise<Result<ListResponse<OrderPickpoint>>> {
    throw new Error('Method is not implemented')
  }

  getMetrikaCodes (): Promise<Result<{ codes: MetrikaCode[] }>> {
    throw new Error('Method is not implemented')
  }

  getProductCardCommercial (options: ProductCardCommercialOptions): Promise<Result<MainSliderItem[]>> {
    throw new Error('Method is not implemented')
  }

  getLicenses (regionId: string, sectionId: string): Promise<Result<License[]>> {
    throw new Error('Method is not implemented')
  }

  getUserDefaultAddress (regionId: string): Promise<Result<UserDefaultAddress>> {
    throw new Error('Method is not implemented')
  }

  setUserDefaultAddress (regionId: string, data: UserDefaultAddress): Promise<Result<UserDefaultAddress>> {
    throw new Error('Method is not implemented')
  }

  getSiteSetting (): Promise<Result<SiteSetting>> {
    throw new Error('Method is not implemented')
  }

  setUserPushPermission (value: boolean, pushToken: string): Promise<Result<BooleanStatus>> {
    throw new Error('Method is not implemented')
  }

  getAnalogs (options: ProductDetailOptions): Promise<Result<ListResponse<Product>>> {
    throw new Error('Method is not implemented')
  }

  getRecommendedProducts (options: ProductDetailOptions): Promise<Result<ListResponse<Product>>> {
    throw new Error('Method is not implemented')
  }

  fetchCategoryFilters (options: Omit<CatalogOptions, 'limit' | 'offset' | 'filter'>): Promise<Result<{ filters: [] }>> {
    throw new Error('Method is not implemented')
  }

  fetchCategoriesByLetter (option: CategoriesOptions): Promise<Result<CategoriesResponse>> {
    throw new Error('Method is not implemented')
  }

  fetchCategoriesByParentId (option: CategoriesOptions): Promise<Result<CategoriesResponse>> {
    throw new Error('Method is not implemented')
  }

  getDeliveryAvailable (regionId: string, address: AddressDadata): Promise<Result<BooleanStatus>> {
    throw new Error('Method is not implemented')
  }

  getDpdStatus (): Promise<Result<Boolean>> {
    throw new Error('Method is not implemented')
  }

  getSliderProducts (sliderType: string, options: ListOptions): Promise<Result<SliderProducts<Product>>> {
    throw new Error('Method is not implemented')
  }

  getTagsCloud (categoryId: string, regionId: string, sectionId: string): Promise<Result<ITagsCloudElement[]>> {
    throw new Error('Method is not implemented')
  }

  getLandingPage (options: ILandingPageOptions): Promise<Result<ILandingPage>> {
    throw new Error('Method is not implemented')
  }

  getLandingPageFilters (options: ILandingPageOptions): Promise<Result<FilterItem[]>> {
    throw new Error('Method is not implemented')
  }
}
